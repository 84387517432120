import React, { FunctionComponent, useState, useEffect } from 'react';
import { Grid, Box, Container } from "@material-ui/core";
import { PageProps } from 'gatsby';
import { Helmet } from "react-helmet"
import { makeStyles } from '@material-ui/core/styles';
import { WindowLocation } from '../types/PageProps';
import Layout from '../components/layout';
import QueueAnim from 'rc-queue-anim';
import imageOne from '../images/IMG_17700.jpg';

const useStyles = makeStyles({
  paper: {
    marginTop: 25,
    paddingTop: 25,
    paddingBottom: 25,
  },
  image: {
    height: 750,
    width: '100%',
    borderRadius: 10,
    marginTop: 25
  },
});

const Index: FunctionComponent<PageProps> = ({ location }) => {
  const classes = useStyles();
  const [locationState, setLocationState] = useState<WindowLocation<unknown> | undefined>(location);

  useEffect(() => {
    if (location) {
      setLocationState(locationState);
    };
  }, []);

  const [items] = useState({
    items: [{
      children: 'Dolores & Company has been serving Northwest Columbus for twenty-five years. ',
      key: 1
    }, {
      children: 'We are the only salon in Central Ohio trained in the art of color concentration.',
      key: 2
    },{
      children: 'This procedure allows us to infuse multiple colors into the hair for a natural effect without fading.',
      key: 3
    }, {
      children: '  We also offer a unique Japanese treatment that strengthens the hair and lasts up to three months. ',
      key: 4
    }, {
      children: 'Come visit our salon and see what your hair has been missing! ',
      key: 5
    }]
  });

  return (
    <Layout location={locationState}>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dolores Hair Designers</title>
          <link rel="canonical" href="http://doloreshairdesigners.com" />
        </Helmet>
      </div>
      <Container maxWidth="lg" >
        <Grid container justifyContent="center" alignItems="center" spacing={5}>
          <Grid item xs={6}>
            <Box className={classes.paper}>
              <QueueAnim type={['top', 'bottom']} interval={[100, 200]} delay={[0, 1000]}
                duration={[1000, 2000]}
                ease={['easeOutBack', 'easeInOutCirc']} leaveReverse
              >
                {items.items.map((item) => <div style={{fontSize: 25}} key={item.key}>
                  {item.children}
                </div>)}
              </QueueAnim>
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={5}>
          <Grid item>
            <img src={imageOne} alt="salon" style={{borderRadius: 10, marginTop: 25, width: '100%'}}  />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Index;